import $ from "jquery";

$(document).ready(function () {
    $(".toggle-password").click(function () {
        let input = $(this).closest(".input-group").find("input");
        if (input.attr("type") === "password") {
            input.attr("type", "text"); // Bascule vers "text"
            $(this).removeClass("fa-eye").addClass("fa-eye-slash"); // Changer l'icône
        } else {
            input.attr("type", "password"); // Revenir à "password"
            $(this).removeClass("fa-eye-slash").addClass("fa-eye"); // Revenir à l'icône initiale
        }
    });



});





